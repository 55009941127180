import { getClientAds, sendImpression } from "api-v2/kevel";
import { Decision } from "api-v2/kevel/types";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { InView, useInView } from "react-intersection-observer";
import { cn, formatPrice } from "../../utils";
import { Icon } from "./icon/icon";
import { Button } from "./button";
import { gtmEvent, gtmPurchaseEvent } from "utils/gtm";
import { useVisibility } from "../../hooks/use-visibility";
import { MinimalProduct } from "api-v2/products/types";
import { ReviewStars } from "./review-stars";

type DecisionData = {
  ctProduct?: MinimalProduct;
  ctShopId?: string;
  ctTitle: string;
  ctDisplayImageUrl?: string;
  ctClickoutLinkText: string;
  ctText: string;
  ctTarget: string;
  ctCpcValue: number;
};

export interface KevelWaterfallProps {
  mobile?: boolean;
  desktop?: boolean;
  className?: string;
  style?: CSSProperties;
}

const KevelWaterfall = ({
  desktop,
  mobile,
  className,
  style,
  ...rest
}: KevelWaterfallProps) => {
  const { ref, inView } = useInView({ threshold: 0.5 });

  const [visibleTime, setVisibleTime] = useState(0);

  const [ads, setAds] = useState<Decision<DecisionData>[] | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [viewEventFired, setViewEventFired] = useState(false);
  const tabActive = useVisibility();
  const refreshInterval = useRef<NodeJS.Timeout>();

  const onFetchAd = async (inView: boolean) => {
    if (!inView) return;

    const properties = window.googletag
      .pubads()
      .getTargetingKeys()
      .reduce((targeting: Record<string, string>, key) => {
        const value = window.googletag.pubads().getTargeting(key)[0];
        if (value) targeting[key] = value;

        return targeting;
      }, {});

    const isMobile =
      typeof window !== "undefined"
        ? window.matchMedia("(max-width: 768px)").matches
        : false;

    const { data: kevelAds } = await getClientAds({
      placements: [
        {
          divName: "productTile",
          adTypes: [301],
          count: isMobile ? 1 : 2,
          properties: { ...properties },
        },
      ],
      keywords: ["waterval"],
    });

    const currentAds = kevelAds.decisions.productTile;

    if (currentAds) {
      setAds(currentAds);

      currentAds.forEach((ad) => {
        gtmEvent(`kevel_native_ad_delivered`, ad);
        sendImpression(ad.impressionUrl);
        console.log(ad.adId, "Sending delivered event");
      });
    }

    setIsLoaded(true);
  };

  const sendViewEvent = async (ad: Decision<DecisionData>) => {
    gtmEvent("kevel_display_ad_inview", ad);

    const viewEvent = ad.events.find(({ id }) => id === 40);
    if (!viewEvent) return;

    await sendImpression(viewEvent.url);
    console.log(ad.adId, "Sending view event");
  };

  useEffect(() => {
    if (!ads) return;

    if (inView && tabActive) {
      if (visibleTime === 1 && !viewEventFired) {
        ads.forEach((ad) => void sendViewEvent(ad));
        setViewEventFired(true);
      }

      if (visibleTime >= 30) {
        clearInterval(refreshInterval.current);
      } else {
        refreshInterval.current = setInterval(() => {
          setVisibleTime((prev) => prev + 1);
        }, 1000);
      }
    } else {
      return clearTimeout(refreshInterval.current);
    }

    return () => {
      return clearTimeout(refreshInterval.current);
    };
  }, [ads, inView, tabActive, visibleTime, viewEventFired]);

  const handleClickout = (ad: Decision<DecisionData>) => {
    const data = ad.contents[0].data;
    const product = data.ctProduct;
    const priceLowest = product?.lowestPrices?.[0];

    console.log("ad clicked", ad);

    gtmEvent("kevel_native_ad_clicked", ad);
    gtmPurchaseEvent({
      value: priceLowest!.cpcRate,
      webshop: priceLowest!.shopName,
      position: "direct naar goedkoopste",
      productgroup: product!.categorySlug,
      product_brand: product!.brand,
      product_id: product!.kieskeurigId,
      title: product!.title,
      ranking: priceLowest!.ranking,
      affiliate: priceLowest!.affiliate,
      additionalValue: Number(data.ctCpcValue || 0),
    });
  };

  return (
    <InView
      onChange={onFetchAd}
      triggerOnce
      className={cn(
        "w-full",
        { "hidden md:block": desktop, "block md:hidden": mobile },
        className
      )}
      style={isLoaded ? {} : style}
    >
      <div
        ref={ref}
        className={cn({
          "p-3 rounded bg-[#F7F7F7] flex flex-col gap-4 lg:px-8 lg:py-6":
            ads && ads.length > 0,
        })}
      >
        {ads?.map((ad) => {
          const data = ad.contents[0].data;
          const product = data.ctProduct;
          let priceLowest = product?.lowestPrices?.[0];

          if (priceLowest?.amount === 0) priceLowest = undefined;

          const clickUrl =
            priceLowest && data.ctShopId
              ? `${ad.clickUrl}&url=${priceLowest.link}`
              : ad.clickUrl;

          return (
            <a
              href={clickUrl}
              target={data.ctTarget}
              key={ad.adId}
              className={cn(
                "grid grid-cols-[114px_1fr] bg-card border hover:no-underline text-foreground",
                "lg:grid-cols-[200px_1fr]"
              )}
              onClick={() => handleClickout(ad)}
            >
              <div
                className={cn(
                  "relative border-r flex-shrink-0",
                  "lg:min-h-[90px]"
                )}
              >
                <img
                  src={product?.thumbnail || data.ctDisplayImageUrl}
                  alt={product?.title || data.ctTitle || ad.adId.toString()}
                  className="absolute w-full h-full top-1/2 -translate-y-1/2 left-0 p-3 object-contain object-center"
                />

                <span
                  className={cn(
                    "absolute left-1/2 -translate-x-1/2 top-2 border border-muted-foreground bg-white text-xs flex items-center text-muted-foreground h-[22px] px-1.5 rounded",
                    "lg:left-2 lg:translate-x-0"
                  )}
                >
                  Gesponsord
                </span>
              </div>

              <div
                className={cn(
                  "p-3 flex flex-col gap-3",
                  "lg:grid lg:grid-cols-8 lg:gap-6 lg:p-4"
                )}
              >
                <div
                  className={cn(
                    "flex flex-col-reverse gap-1 col-span-3",
                    "lg:flex-col"
                  )}
                >
                  <h2 className="text-primary text-lg font-bold line-clamp-2 leading-6">
                    {product?.title || data.ctTitle}
                  </h2>

                  {Boolean(product?.reviewCount && product.reviewScore) && (
                    <ReviewStars
                      score={product!.reviewScore}
                      count={product!.reviewCount}
                    />
                  )}
                </div>

                <div
                  className={cn("flex-1 flex items-center", "lg:col-span-3")}
                >
                  {Boolean(product?.specificationSpotlight) && (
                    <ul className="flex flex-1 flex-wrap justify-center text-accent-foreground flex-col gap-1">
                      {Object.keys(product!.specificationSpotlight).map(
                        (key) => (
                          <li key={key} className="flex">
                            <span className="hidden lg:inline-block">
                              {key}
                            </span>
                            <span className="line-clamp-1 font-bold">
                              <span className="px-1">•</span>
                              <span>
                                {product!.specificationSpotlight[key]}
                              </span>
                            </span>
                          </li>
                        )
                      )}
                    </ul>
                  )}

                  {Boolean(!product?.specificationSpotlight && priceLowest) && (
                    <div className="flex items-center text-success">
                      {priceLowest!.textAd}
                    </div>
                  )}
                </div>

                <div
                  className={cn(
                    "col-span-2 flex flex-col justify-between gap-2 lg:gap-0 lg:items-end",
                    { "lg:justify-center": !priceLowest }
                  )}
                >
                  {priceLowest && (
                    <div className="flex lg:justify-end">
                      <span className="font-bold lg:font-normal">
                        v.a.{" "}
                        <span className="font-bold text-base lg:text-lg">
                          {formatPrice(priceLowest.amount)}
                        </span>
                      </span>

                      {product.discount > 0 && (
                        <span className="inline-flex gap-1 text-success items-center font-semibold">
                          <Icon icon="prijsdaler" size={16} />
                          {`-${product.discount}%`}
                        </span>
                      )}
                    </div>
                  )}

                  <Button asChild>
                    <a href={clickUrl} target={data.ctTarget}>
                      {data.ctClickoutLinkText}
                    </a>
                  </Button>
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </InView>
  );
};

export { KevelWaterfall };
